.title {
  font-weight: 700;
  font-size: 32px;
  color: #132c1d;
  letter-spacing: 0.03em;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: left;
}

.subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #2f2f2f;
  margin-bottom: 40px;
}
.labelWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.label {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.02em;
  color: #2f2f2f;
}

.label img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}
.button {
  background: #165d5c;
  border-radius: 28px;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  height: 56px;
  padding-right: 1.5rem;
  padding-left: 1.125rem;
  border: 0;
}

.button:hover {
  background-color: #276e6d;
}

.button img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.CircularProgressbarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  position: relative;
}

.CircularProgressbarWrapper > svg {
  height: 300px;
  width: 300px;
}

.CircularProgressbarWrapper > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 56px;
}

.CircularProgressbarWrapper > p > span {
  font-size: 32px;
}

.titleSection {
  display: flex;
  justify-content: space-between;
}

.calendarCountrySection {
  display: flex;
  flex-direction: row;
}

.deleteModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
}

.deleteModalOverlay {
  background-color: #000;
  opacity: 0.2;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 110;
}

.deleteModalBox {
  background-color: #fff;
  padding: 55px 24px 50px;
  position: relative;
  max-width: 410px;
  z-index: 120;
}

.deleteModalText {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
}

.deleteModalClose {
  position: absolute;
  right: 20px;
  top: 20px;
  border: 0;
  background-color: #fff;
}

.deleteModalButtonsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.deleteModalButtonsBox > button {
  border-radius: 24px;
  height: 40px;
  width: 168px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}

.deleteModalButtonPrimary {
  color: #165d5c;
  background-color: #fff;
  border: 1px solid #d9dfdd;
}

.deleteModalButtonPrimary:hover {
  transition: all 0.3s ease-in-out;
  background-color: #f2f2f2;
  border: 1px solid #d9dfdd;
}

.deleteModalButtonSecondary {
  color: #fff;
  border: 1px solid #165d5c;
  background-color: #165d5c;
}

.deleteModalButtonSecondary:hover {
  transition: all 0.3s ease-in-out;
  background-color: #276e6d;
}
