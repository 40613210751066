div.rightColumn {
  display: flex;
  width: 50%;
}

.leftColumn {
  display: flex;
  width: 50%;
}

.leftColumn > div {
  width: 50%;
}

.top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.top > div {
  width: 33%;
}

.bottom {
  width: 100%;
}

.bottom > div {
  display: flex;
  justify-content: space-between;
}

.bottom > div > div {
  width: 50%;
  padding-left: 24px;
  padding-right: 10px;
  margin-bottom: 0;
}

.bottom > p {
  padding-left: 24px;
}

.bottom .titleWrapper {
  justify-content: normal;
}

.bottom .titleWrapper .title {
  margin-right: 12px;
}

.border {
  border-right: 1px solid #b6bbb9;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  color: #2f2f2f;
  font-weight: 600;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
}

.title > img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.titleImageBig {
  width: 24px;
  height: 24px;
}

.box {
  border-right: 1px solid #b6bbb9;
  color: #606462;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 24px;
  padding-right: 10px;
}

.rightColumn .box:last-child {
  border: 0;
}

.percent > p > img {
  width: 9px;
  height: 11px;
  margin-right: 7px;
}
