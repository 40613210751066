.titleWrapper {
  border-bottom: 1px solid #d9dfdd;
  margin-bottom: 24px;
}

.title {
  color: #2f2f2f;
  font-weight: 600;
  font-size: 20px;
}

.boxInside {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  position: relative;
}

.noData {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border: 1px solid #d9dfdd;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
}
