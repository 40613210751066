.container {
  padding-top: 2.25rem;
  padding-right: 2.5rem;
  text-align: left;
  color: #2f2f2f;
  width: calc(100vw - 23.375rem);
}

.titleHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  color: #2f2f2f;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.title > img {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.percentBox {
  display: flex;
  flex-direction: column;
  color: #165d5c;
}

.percentBox input[type='range'] {
  margin-top: 30px;
  accent-color: #165d5c;
  width: 100%;
}

.percentBox .textinputwrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 36px;
}

.percentBox .textinputwrap input::placeholder {
  white-space: pre-line;
}

.rangeLegend {
  color: #2f2f2f;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
}

.percentBox input[type='number'] {
  -moz-appearance: textfield;
  color: #165d5c;
  background: #f6f6f6;
  border-radius: 16px;
  font-weight: 700;
  font-size: 40px;
  padding: 20px;
  border: 0;
  max-width: 600px;
  height: 200px;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea.textarea {
  background-color: #f6f6f6;
  border: 0;
  border-radius: 4px;
  color: #606462;
  padding: 1.5rem;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.button {
  display: flex;
  height: 2.5rem;
  border-radius: 1.5rem;
  cursor: pointer;
  padding-left: 1.125rem;
  padding-right: 1.5rem;
  text-decoration: none;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  transition: background-color 0.3s ease-in-out;
}

.addbutton {
  border: 1px solid #165d5c;
  background-color: #165d5c;
  margin-left: 0;
}

.addbutton .icon {
  margin-right: 0.7rem;
}

.addbutton:hover {
  transition: background-color 0.3s ease-in-out;
  background-color: #276e6d;
}

.addbutton > span {
  font-weight: 700;
  font-size: 0.875rem;
  color: #fff;
}

.button .icon {
  width: 1.25rem;
  height: 1.25rem;
}

.editButton {
  border: 1px solid #d9dfdd;
  background-color: #fff;
  margin-left: 0.5rem;
}

.editButton > span {
  font-weight: 700;
  font-size: 0.875rem;
  color: #2f2f2f;
  margin-right: 0.5rem;
}

.deleteModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
}

.deleteModalOverlay {
  background-color: #000;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.deleteModalBox {
  background-color: #fff;
  padding: 55px 24px 50px;
  position: relative;
  max-width: 410px;
}

.deleteModalText {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
}

.deleteModalClose {
  position: absolute;
  right: 20px;
  top: 20px;
  border: 0;
  background-color: #fff;
}

.deleteModalButtonsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.deleteModalButtonsBox > button {
  border-radius: 24px;
  height: 40px;
  width: 168px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}

.deleteModalButtonPrimary {
  color: #165d5c;
  background-color: #fff;
  border: 1px solid #d9dfdd;
}

.deleteModalButtonPrimary:hover {
  transition: all 0.3s ease-in-out;
  background-color: #f2f2f2;
  border: 1px solid #d9dfdd;
}

.deleteModalButtonSecondary {
  color: #fff;
  border: 1px solid #165d5c;
  background-color: #165d5c;
}

.deleteModalButtonSecondary:hover {
  transition: all 0.3s ease-in-out;
  background-color: #276e6d;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: -1.5rem;
}
