.calendarWrapper {
  position: absolute;
  z-index: 3;
  right: 40px;
  display: flex;
}

.calendarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.calendarButton {
  height: 3rem;
  background-color: #fff;
  border: 1px solid #d9dfdd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  padding-left: 3rem;
  color: #606462;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 15px;
}

.calendarButton:hover {
  transition: background-color 0.3s ease-in-out;
  background-color: #f2f2f2;
}

.calendarButton > img {
  height: 21px;
  width: 21px;
  margin-left: 2.5rem;
}

.title {
  font-weight: 700;
  font-size: 32px;
  color: #132c1d;
  letter-spacing: 0.03em;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: left;
}

.subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #2f2f2f;
  margin-bottom: 40px;
}
.labelWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.label {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.02em;
  color: #2f2f2f;
}

.label img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.button {
  background: #165d5c;
  border-radius: 28px;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  height: 56px;
  padding-right: 1.5rem;
  padding-left: 1.125rem;
  border: 0;
}

.button:hover {
  background-color: #276e6d;
}

.button img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.titleSection {
  display: flex;
  justify-content: space-between;
}

.calendarCountrySection {
  display: flex;
  flex-direction: row;
}
