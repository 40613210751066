.container {
  background: #ffffff;
  border: 1px solid #d9dfdd;
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.container:first-child {
  margin-left: 0;
}

.container:last-child {
  margin-right: 0;
}

.container.recommendation:first-child,
.container.recommendation:last-child {
  margin-left: 0;
  margin-right: 0;
}

.titleWrapper {
  border-bottom: 1px solid #d9dfdd;
  margin-bottom: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  color: #2f2f2f;
  font-weight: 600;
  font-size: 20px;
  margin-top: 0;
}

.secondText {
  color: #2f2f2f;
  font-weight: 600;
  font-size: 20px;
  margin-top: 0;
  width: 50%;
}

.dashboard.container {
  margin-left: 0;
  margin-right: 0;
}