@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
}

.js-plotly-plot .plotly,
.js-plotly-plot .plotly div,
button,
.react-datepicker.calendar {
  font-family: 'Poppins', sans-serif;
  border: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.react-calendar__tile--active {
  background-color: #165d5c;
}

button.react-calendar__tile--active:enabled:hover,
button.react-calendar__tile--active:enabled:focus {
  background-color: #276e6d;
}

.MuiAutocomplete-popper {
  z-index: 10;
  position: relative;
}
rect.cls-1,
.modebar-btn.plotlyjsicon.modebar-btn--logo {
  display: none;
}

.calendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #165d5c;
}

.calendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #165d5c;
}

.calendar .react-datepicker__month--selected,
.calendar .react-datepicker__month--in-selecting-range,
.calendar .react-datepicker__month--in-range,
.calendar .react-datepicker__quarter--selected,
.calendar .react-datepicker__quarter--in-selecting-range,
.calendar .react-datepicker__quarter--in-range {
  background-color: #165d5c;
}

.calendar .react-datepicker__month--selected:hover,
.calendar .react-datepicker__month--in-selecting-range:hover,
.calendar .react-datepicker__month--in-range:hover,
.calendar .react-datepicker__quarter--selected:hover,
.calendar .react-datepicker__quarter--in-selecting-range:hover,
.calendar .react-datepicker__quarter--in-range:hover {
  background-color: #165d5c;
}

.calendar .react-datepicker__day--selected,
.calendar .react-datepicker__day--in-selecting-range,
.calendar .react-datepicker__day--in-range,
.calendar .react-datepicker__month-text--selected,
.calendar .react-datepicker__month-text--in-selecting-range,
.calendar .react-datepicker__month-text--in-range,
.calendar .react-datepicker__quarter-text--selected,
.calendar .react-datepicker__quarter-text--in-selecting-range,
.calendar .react-datepicker__quarter-text--in-range,
.calendar .react-datepicker__year-text--selected,
.calendar .react-datepicker__year-text--in-selecting-range,
.calendar .react-datepicker__year-text--in-range {
  background-color: #165d5c;
}

.calendar .react-datepicker__day--selected:hover,
.calendar .react-datepicker__day--in-selecting-range:hover,
.calendar .react-datepicker__day--in-range:hover,
.calendar .react-datepicker__month-text--selected:hover,
.calendar .react-datepicker__month-text--in-selecting-range:hover,
.calendar .react-datepicker__month-text--in-range:hover,
.calendar .react-datepicker__quarter-text--selected:hover,
.calendar .react-datepicker__quarter-text--in-selecting-range:hover,
.calendar .react-datepicker__quarter-text--in-range:hover,
.calendar .react-datepicker__year-text--selected:hover,
.calendar .react-datepicker__year-text--in-selecting-range:hover,
.calendar .react-datepicker__year-text--in-range:hover {
  background-color: #165d5c;
}

.calendar .react-datepicker__month-text.react-datepicker__month--selected:hover,
.calendar .react-datepicker__month-text.react-datepicker__month--in-range:hover,
.calendar
  .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.calendar
  .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.calendar
  .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.calendar
  .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.calendar
  .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.calendar
  .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #165d5c;
}
